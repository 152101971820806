import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DragNDropService {
  public hideTicketOnEmptyState(data): void {
    const draggedElementPlaceholderTarget = data.container.element.nativeElement.children[1];
    draggedElementPlaceholderTarget.style.visibility = 'hidden';
  }

  public showTicketOnEmptyState(data): void {
    const draggedElementPlaceholderTarget = data.container.element.nativeElement.children[1];
    draggedElementPlaceholderTarget.style.visibility = 'visible';
  }
}
